<template>
  <div class="page-builder">
    <h1>Editor de Página</h1>

    <div class="grid">
      <div class="page-content">
        <div class="page-details" v-if="!loading">
          <h2>Configurações da página</h2>
          <div>
            <label for="title">Título da Página</label>
            <input type="text" id="title" v-model="page.title" placeholder="Título da Página">
          </div>
          <div>
            <label for="url">URL amigável da página</label>
            <input type="text" id="url" v-model="page.url" v-on:keyup="formatURL" placeholder="URL amigável ex: pagina-de-exemplo">
          </div>
          <div>
            <label for="parent">Página pai</label>
            <select id="parent" v-model="page.parent">
              <option value="0">Esta é uma página pai</option>
              <option v-for="p in parentPages" :key="p.id" :value="p.id">{{ p.title }}</option>
            </select>
          </div>
          <div>
            <label for="sort">Ordenação da página</label>
            <input type="number" min="1" id="sort" v-model="page.sort" placeholder="Ordem crescente de prioridade (1 aparece antes de 2, por exemplo)">
          </div>
          <div>
            <label for="on_menu">Mostrar página no menu</label>
            <select id="on_menu" v-model="page.on_menu">
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>
          </div>
        </div>

        <div v-if="!loading">
          <br>
          <br>
        </div>

        <h2 v-if="!loading" style="margin-bottom: 20px">Conteúdo da página</h2>
        <p v-if="blocks.length === 0 && !loading">Nenhum bloco na página. Adicione blocos clicando na barra lateral direita.</p>
        <p v-if="loading">Carregando conteúdo da página...</p>
        <draggable v-model="blocks">
          <component v-for="blockComponent in blocks" v-bind:is="blockComponent.name" :key="blockComponent.id"
                  @delete="deleteBlock($event)" :blockData="blockComponent" ref="blockComponent"></component>
        </draggable>
      </div>

      <div>
        <div class="tools">
          <h4>Página</h4>
          <button class="save-btn" v-on:click="savePage" title="Clique para salvar a página" :disabled="saving">
            <font-awesome-icon icon="save"/>
            <br>
            {{ saving ? 'Salvando...' : 'Salvar' }}
          </button>
          <button class="delete-btn" v-on:click="deletePage" title="Clique para excluir esta página">
            <font-awesome-icon icon="trash"/>
            <br>
            {{ deleting ? 'Excluindo...' : 'Excluir' }}
          </button>
          <hr>
          <h4>Blocos</h4>
          <button v-on:click="addBlock('text')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="font"/>
            <br>
            Texto
          </button>
          <button v-on:click="addBlock('image')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="image"/>
            <br>
            Imagem
          </button>
          <button v-on:click="addBlock('button')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="link"/>
            <br>
            Botão
          </button>
          <button v-on:click="addBlock('file')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="file"/>
            <br>
            Arquivo
          </button>
          <button v-on:click="addBlock('video')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="video"/>
            <br>
            Vídeo
          </button>
          <button v-on:click="addBlock('gallery')" title="Clique para adicionar este bloco">
            <font-awesome-icon icon="images"/>
            <br>
            Galeria de Fotos
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import TextBlock from "./blocks/TextBlock";
import ButtonBlock from "./blocks/ButtonBlock";
import ImageBlock from "./blocks/ImageBlock";
import VideoBlock from "./blocks/VideoBlock";
import FileBlock from "./blocks/FileBlock";
import GalleryBlock from "./blocks/GalleryBlock";
const axios = require('axios').default;

export default {
  name: 'PageBuilder',
  components: {
    draggable,
    TextBlock,
    ButtonBlock,
    ImageBlock,
    VideoBlock,
    FileBlock,
    GalleryBlock
  },
  props: {
    pageId: String
  },
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      pages: [],
      page: {},
      blocks: [],
      blocksData: []
    }
  },
  created() {
    let self = this;
    axios.get(this.$apiUrl + 'pages/list')
            .then(response => {
              self.pages = response.data.pages;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'pages/page/' + this.pageId)
            .then(response => {
              self.page = response.data.page;
              let savedBlocks = JSON.parse(self.page.content);
              if (savedBlocks) {
                savedBlocks.forEach(b => {
                  self.addBlockWithData(b);
                });
              }
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    formatURL() {
      this.page.url = this.page.url.replace(/\s+/g, '-').toLowerCase();
    },
    addBlockWithData(block) {
      this.blocks.push({
        name: this.getNameByType(block.type),
        id: '_' + Math.random().toString(36).substr(2, 9),
        data: block.data
      });
    },
    addBlock(type) {
      this.blocks.push({
        name: this.getNameByType(type),
        id: '_' + Math.random().toString(36).substr(2, 9),
        data: {
          content: {}
        },
      });
    },
    getNameByType(type) {
      switch (type) {
        case 'text':
          return 'TextBlock';
        case 'button':
          return 'ButtonBlock';
        case 'image':
          return 'ImageBlock';
        case 'video':
          return 'VideoBlock';
        case 'file':
          return 'FileBlock';
        case 'gallery':
          return 'GalleryBlock';
      }
    },
    savePage() {
      this.saving = true;
      this.blocksData = [];

      this.blocks.forEach(block => {
        let bComponent = this.$refs.blockComponent.filter(b => {
          return b.blockData.id === block.id;
        });
        this.blocksData.push(bComponent[0].getData());
      });

      let self = this;
      axios.put(this.$apiUrl + 'pages/page/' + this.$route.params.id, { page: this.page, content: this.blocksData })
              // eslint-disable-next-line no-unused-vars
              .then(response => {
                self.saving = false;
                this.$alert('Página salva com sucesso!', 'Sucesso', 'success');
              })
              .catch(error => {
                this.$alert('Falha ao salvar página.', 'Erro', 'error');
                console.log(error.response.data.error);
                self.saving = false;
              });
    },
    deletePage() {
      let self = this;
      this.$confirm('Certeza que deseja excluir a página?', 'Excluir a página', 'question', {confirmButtonText: 'Sim', cancelButtonText: 'Cancelar'})
              .then(() => {
                self.deleting = true;
                axios.delete(this.$apiUrl + 'pages/page/' + this.$route.params.id)
                        // eslint-disable-next-line no-unused-vars
                        .then(response => {
                          self.deleting = false;
                          this.$alert('Página excluída com sucesso!', 'Sucesso', 'success').then(() => {
                            self.$router.push('/paginas');
                          })
                        })
                        .catch(error => {
                          this.$alert('Falha ao excluir página.', 'Erro', 'error');
                          console.log(error.response.data.error);
                          self.deleting = false;
                        });
              })
              .catch(() => {}
            );
    },
    deleteBlock(blockId) {
      this.blocks = this.blocks.filter((b) => {
        return b.id !== blockId
      })
    }
  },
  computed: {
    parentPages() {
      return this.pages.filter(p => {
        return p.parent === 0;
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/scss/global";
  @import "../assets/scss/buttons";

  .page-builder {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px 0;


    .grid {
      display: grid;
      grid-template-columns: 1fr 100px;
      height: 100%;

      .page-content {
        padding: 50px;
      }

      .tools {
        position: sticky;
        top: 50px;
        background-color: $dark;
        padding: 10px 0 0;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20.5V18H0v-2h20v-2H0v-2h20v-2H0V8h20V6H0V4h20V2H0V0h22v20h2V0h2v20h2V0h2v20h2V0h2v20h2V0h2v20h2v2H20v-1.5zM0 20h2v20H0V20zm4 0h2v20H4V20zm4 0h2v20H8V20zm4 0h2v20h-2V20zm4 0h2v20h-2V20zm4 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2zm0 4h20v2H20v-2z' fill='%232b2b2b' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");

        button {
          display: block;
          width: 100%;
          font-size: 0.8rem;

          svg {
            font-size: 1.0rem;
            margin-bottom: 5px;
          }
        }

        h4 {
          color: #fff;
          margin: 0 0 5px;
        }

        hr {
          margin-bottom: 10px;
          border: none;
        }

        .save-btn {
          background-color: $alt;
          &:hover {
            background-color: darken($alt, 10%);
          }
        }

        .delete-btn {
          background-color: $danger;
          &:hover {
            background-color: darken($danger, 10%);
          }
        }
      }
    }
  }

  .page-details {
    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }
  }
</style>
