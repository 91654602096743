<template>
  <div class="editor">
    <PageBuilder :pageId="pageId"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageBuilder from '@/components/PageBuilder.vue'

export default {
  name: 'editPage',
  components: {
    PageBuilder
  },
  data() {
    return {
      loading: true,
      pageId: this.$route.params.id
    }
  }
}
</script>

<style scoped lang="scss">

</style>
